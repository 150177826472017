<script lang="ts" setup>
import SubscriptionManager from '@/components/SubscriptionManager.vue'
import HorizontalProductList from '@/components/HorizontalProductList.vue'
import ProductCard from '@/components/ProductCard.vue'
import Button from '@/components/Button.vue'
import IconSkip from '../assets/icons/skip.svg?component'
import IconAdd from '../assets/icons/add.svg?component'
import IconParcel from '../assets/icons/parcel.svg?component'
import IconBin from '../assets/icons/bin.svg?component'

import { t } from '@/lib/locale'

import { formatDate } from '@/lib/formatters/date'

import type { Subscription } from '@/stores/subscription'
import type { ImageMap } from '@/stores/productImage'
import { AsyncOperationState } from '@/stores/lib/AsyncOperationState'
import { AlternativeProduct } from '@/stores/cancellationIntervention'

const props = defineProps<{
  item: Subscription
  link?: string
  isWorking: boolean
  inNextOrder: boolean
  alternativeProductsLoadState: AsyncOperationState
  alternativeProducts: AlternativeProduct[]
  productImages: Record<string, ImageMap[] | undefined>
}>()

const emit = defineEmits<{
  (e: 'save', update: { quantity: number; frequency: number }): void
  (e: 'cancel'): void
  (e: 'add-to-next-order'): void
  (e: 'reactivate'): void
  (e: 'skip'): void
  (e: 'swap', newExternalProductId: string): void
}>()

const imageForProduct = (id: string) => {
  const images = props.productImages[id]
  if (images) {
    return images[0].medium
  } else {
    return undefined
  }
}
</script>

<template>
  <ProductCard
    v-if="inNextOrder"
    class="Card"
    :title="item.product_title"
    :link="link"
    :image-url="
      imageForProduct(
        `${item.external_product_id.ecommerce}:${item.external_variant_id.ecommerce}`
      )
    "
    :label="{ text: t('purchase_item_in_next_order_label'), Icon: IconParcel }"
    :action="{
      text: t('purchase_item_skip_cta'),
      Icon: IconSkip,
      event: 'skip',
    }"
    :action-enabled="!isWorking"
    @skip="$emit('skip')"
  >
    <template #description>
      <div
        v-html="
          t(
            `purchase_item_subscription_summary_subscribed_${
              item.order_interval_frequency > 1 ? 'plural' : 'singular'
            }_month`,
            {
              quantity: item.quantity.toString(),
              frequency: item.order_interval_frequency.toString(),
            }
          )
        "
      ></div>
    </template>
  </ProductCard>
  <ProductCard
    v-else
    class="Card"
    :title="item.product_title"
    :link="link"
    :image-url="
      imageForProduct(
        `${item.external_product_id.ecommerce}:${item.external_variant_id.ecommerce}`
      )
    "
    :action="
      item.next_charge_scheduled_at
        ? {
            text: t('purchase_item_add_to_next_order_cta'),
            Icon: IconAdd,
            event: 'add-to-next-order',
          }
        : {
            text: t('purchase_item_reactivate_cta'),
            Icon: IconAdd,
            event: 'reactivate',
          }
    "
    :action-enabled="!isWorking"
    @add-to-next-order="$emit('add-to-next-order')"
    @reactivate="$emit('reactivate')"
  >
    <template #description>
      <template v-if="item.next_charge_scheduled_at">
        <span
          v-html="
            t(
              `purchase_item_subscription_summary_subscribed_${
                item.order_interval_frequency > 1 ? 'plural' : 'singular'
              }_month`,
              {
                quantity: item.quantity.toString(),
                frequency: item.order_interval_frequency.toString(),
              }
            )
          "
        ></span
        >&nbsp;
        <span
          v-html="
            t('purchase_item_subscription_summary_next_order', {
              date: formatDate('DD Mon', item.next_charge_scheduled_at),
            })
          "
        ></span>
      </template>
      <template v-else>
        <div
          v-html="
            t(
              `purchase_item_subscription_summary_cancelled_${
                item.order_interval_frequency > 1 ? 'plural' : 'singular'
              }_month`,
              {
                quantity: item.quantity.toString(),
                frequency: item.order_interval_frequency.toString(),
              }
            )
          "
        ></div>
      </template>
    </template>
  </ProductCard>

  <Transition name="fade" appear>
    <div
      v-if="'order_interval_frequency' in item && item.next_charge_scheduled_at"
    >
      <SubscriptionManager
        :quantity="item.quantity"
        :frequency="item.order_interval_frequency"
        :is-working="isWorking"
        @save-changes="$emit('save', $event)"
      ></SubscriptionManager>
    </div>
  </Transition>

  <Transition name="fade" appear>
    <div class="PurchaseItemSubscription__FragranceSwitcher">
      <h3 class="PurchaseItemSubscription__FragranceSwitcher__Title">
        Switch fragrance
      </h3>
      <p>
        This product comes in different fragrances. If you'd like to try a new
        one, please select from the list below.
      </p>
      <div>
        <HorizontalProductList
          :products="
            alternativeProducts.map((p) => ({
              title: `${p.fragrance_name?.value || p.title} ${
                `${p.variants[0].id.replace(
                  'gid://shopify/ProductVariant/',
                  ''
                )}` === item.external_variant_id.ecommerce
                  ? '(current)'
                  : ''
              }`,
              imageUrl: p.images.medium,
              action: {
                text: `Pick this fragrance`,
                callback: () => {
                  emit(
                    'swap',
                    p.variants[0].id.replace(
                      /gid:\/\/shopify\/ProductVariant\//,
                      ''
                    )
                  )
                },
                disabled:
                  `${p.variants[0].id.replace(
                    'gid://shopify/ProductVariant/',
                    ''
                  )}` === item.external_variant_id.ecommerce,
              },
            }))
          "
        ></HorizontalProductList>
      </div>
    </div>
  </Transition>

  <Transition name="fade" appear>
    <Button
      :modifiers="['card', 'inverted', 'error']"
      :disabled="isWorking"
      @click="$emit('cancel')"
      v-if="item.next_charge_scheduled_at"
    >
      <template #iconBefore><IconBin /></template>
      {{ t('purchase_item_cancel_cta') }}
    </Button>
  </Transition>
</template>
<style>
.PurchaseItemSubscription__FragranceSwitcher__Title {
  margin: 10px 35px 12px 0;
}

.PurchaseItemSubscription__FragranceSwitcher .HorizontalProductList {
  margin-bottom: 16px;

  & .HorizontalProductList__Products {
    padding: 16px;
    gap: 0 16px;

    & .ProductCardMini {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
