import type { AuthenticatedEntity } from '@/stores/auth'
import { useNextOrderStore } from '@/stores/nextOrder'
import { useOnetimeStore, type Onetime } from '@/stores/onetime'
import { useSubscriptionStore, type Subscription } from '@/stores/subscription'
import { useCancellationInterventionStore } from '@/stores/cancellationIntervention'
import { useSubscriptionCustomerStore } from '@/stores/subscriptionCustomer'
import { useProductLinkStore } from '@/stores/productLink'
import { computed } from 'vue'
import mixpanel from 'mixpanel-browser'

export function usePurchaseItemState(
  id: string,
  authedUser: AuthenticatedEntity,
  analyticsContext = 'your_bower'
) {
  const nextOrderStore = useNextOrderStore()
  const subscriptionCustomerStore = useSubscriptionCustomerStore()

  const subscriptionStore = useSubscriptionStore()
  const onetimeStore = useOnetimeStore()

  const cancellationInterventionStore = useCancellationInterventionStore()

  const productLinkStore = useProductLinkStore()

  const findItem = () => {
    const search = (item: Subscription | Onetime) => item.id == id
    return (
      subscriptionStore.subscriptions.find(search) ||
      onetimeStore.onetimes.find(search) ||
      null
    )
  }

  const findItemInNextOrder = () => nextOrderStore.getItemById(id)

  const currentItem = computed<Subscription | Onetime | null>(() => findItem())
  const itemInNextOrder = computed(() => findItemInNextOrder())

  nextOrderStore.init(authedUser.rechargeId)
  subscriptionCustomerStore.init(authedUser.rechargeId)
  Promise.all([
    subscriptionStore.init(authedUser.rechargeId),
    onetimeStore.init(authedUser.rechargeId),
  ])

  const dayDiff = (d1: Date, d2: Date) => {
    return (d1.getTime() - d2.getTime()) / 1000 / 60 / 60 / 24
  }

  const update = async (update: { quantity: number; frequency: number }) => {
    await subscriptionStore.updateSubscription(
      authedUser.rechargeId,
      id,
      update
    )
    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)
    // track it
    if (currentItem.value) {
      mixpanel.track('update_item', {
        context: analyticsContext,
        product_id: currentItem.value.external_product_id.ecommerce,
        variant_id: currentItem.value.external_variant_id.ecommerce,
        ...update,
      })
    }
  }

  const updateOnetime = async (update: { quantity: number }) => {
    await onetimeStore.updateOnetime(authedUser.rechargeId, id, update)
    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)
    // track it
    if (currentItem.value) {
      mixpanel.track('update_item', {
        context: analyticsContext,
        product_id: currentItem.value.external_product_id.ecommerce,
        variant_id: currentItem.value.external_variant_id.ecommerce,
        ...update,
      })
    }
  }

  const cancel = async (reason: string, comments?: string) => {
    if (!currentItem.value) return
    if ('order_interval_frequency' in currentItem.value) {
      await subscriptionStore.cancelSubscription(
        authedUser.rechargeId,
        id,
        reason,
        comments
      )
      // reinit next order
      nextOrderStore.load(authedUser.rechargeId)
    } else {
      await onetimeStore.cancelOnetime(authedUser.rechargeId, id)
      // reinit next order
      nextOrderStore.load(authedUser.rechargeId)
    }
    // track it
    mixpanel.track('cancel_item', {
      reason,
      product_id: currentItem.value.external_product_id.ecommerce,
      variant_id: currentItem.value.external_variant_id.ecommerce,
      is_subscription: 'order_interval_frequency' in currentItem.value,
      context: analyticsContext,
    })
  }

  const addToNextOrder = async () => {
    await subscriptionStore.addSubscriptionToNextOrder(
      authedUser.rechargeId,
      id
    )
    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)
    // track it
    mixpanel.track('add_to_order', {
      ...(currentItem.value && currentItem.value.next_charge_scheduled_at
        ? {
            product_id: currentItem.value.external_product_id.ecommerce,
            variant_id: currentItem.value.external_variant_id.ecommerce,
            is_subscription: true,
            is_new: false,
            n_days_until_previous_charge: dayDiff(
              new Date(currentItem.value.next_charge_scheduled_at),
              new Date()
            ),
            context: analyticsContext,
          }
        : {}),
    })
  }

  const reactivate = async () => {
    await subscriptionStore.reactivateSubscription(authedUser.rechargeId, id)
    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)
    // track it
    mixpanel.track('reactivate_item', {
      context: analyticsContext,
      ...(currentItem.value && currentItem.value.next_charge_scheduled_at
        ? {
            product_id: currentItem.value.external_product_id.ecommerce,
            variant_id: currentItem.value.external_variant_id.ecommerce,
          }
        : {}),
    })
  }

  const skip = async () => {
    await subscriptionStore.skipSubscription(authedUser.rechargeId, id)
    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)
    // track it
    mixpanel.track('skip_item', {
      context: analyticsContext,
      ...(currentItem.value && currentItem.value.next_charge_scheduled_at
        ? {
            product_id: currentItem.value.external_product_id.ecommerce,
            variant_id: currentItem.value.external_variant_id.ecommerce,
            n_days_until_previous_charge: dayDiff(
              new Date(currentItem.value.next_charge_scheduled_at),
              new Date()
            ),
          }
        : {}),
    })
  }

  const link = computed(() => {
    if (currentItem.value) {
      return productLinkStore.generateLinkForProduct(
        currentItem.value.external_product_id.ecommerce,
        currentItem.value.external_variant_id.ecommerce
      )
    } else {
      return undefined
    }
  })

  const cancelInterventionSwap = async (
    subscriptionId: string,
    variantId: string,
    showToast: boolean
  ) => {
    await cancellationInterventionStore.swap(
      authedUser.rechargeId,
      subscriptionId,
      variantId,
      showToast
    )

    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)

    if (showToast) {
      mixpanel.track('subscription_swap', {
        context: analyticsContext,
        ...(currentItem.value && currentItem.value.next_charge_scheduled_at
          ? {
              product_id: currentItem.value.external_product_id.ecommerce,
              variant_id: currentItem.value.external_variant_id.ecommerce,
            }
          : {}),
      })
    } else {
      mixpanel.track('cancel_intervention_swap', {
        context: analyticsContext,
        ...(currentItem.value && currentItem.value.next_charge_scheduled_at
          ? {
              product_id: currentItem.value.external_product_id.ecommerce,
              variant_id: currentItem.value.external_variant_id.ecommerce,
            }
          : {}),
      })
    }
  }

  const cancelInterventionAddToNextOrder = async (subscriptionId: string) => {
    await cancellationInterventionStore.addToNextOrder(
      authedUser.rechargeId,
      subscriptionId
    )

    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)

    mixpanel.track('cancel_intervention_add_to_next_order', {
      context: analyticsContext,
      ...(currentItem.value && currentItem.value.next_charge_scheduled_at
        ? {
            product_id: currentItem.value.external_product_id.ecommerce,
            variant_id: currentItem.value.external_variant_id.ecommerce,
          }
        : {}),
    })
  }

  const cancelInterventionDelay = async (
    subscriptionId: string,
    until: string
  ) => {
    await cancellationInterventionStore.delay(
      authedUser.rechargeId,
      subscriptionId,
      until
    )

    // reinit next order
    nextOrderStore.load(authedUser.rechargeId)

    mixpanel.track('cancel_intervention_delay', {
      context: analyticsContext,
      ...(currentItem.value && currentItem.value.next_charge_scheduled_at
        ? {
            product_id: currentItem.value.external_product_id.ecommerce,
            variant_id: currentItem.value.external_variant_id.ecommerce,
          }
        : {}),
    })
  }

  return {
    currentItem,
    itemInNextOrder,
    update,
    updateOnetime,
    cancel,
    addToNextOrder,
    skip,
    reactivate,
    link,
    cancelInterventionSwap,
    cancelInterventionAddToNextOrder,
    cancelInterventionDelay,
  }
}
